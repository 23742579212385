import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router, NavigationStart, NavigationEnd} from '@angular/router';
import {Helpers} from './helpers';
import {SettingsService} from '../app/theme/pages/main-components/settings/settings.service';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {MathContent} from './../math/math-content';
import { io } from 'socket.io-client';
import { UserService } from './_services/user.service';

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'app';
  globalBodyClass = 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';


  mathMl2: MathContent = {
    mathml: `<h1>emad</h1><math xmlns="http://www.w3.org/1998/Math/MathML"><msubsup><mo>∫</mo><mn>1</mn><mn>3</mn></msubsup><mn>2</mn><mo>d</mo><mn>2</mn></math>`,
  };
  mathMl: MathContent = {
    mathml: `<math xmlns="http://www.w3.org/1998/Math/MathML"><munder><mi>lim</mi><mrow><mn>2</mn><mo>→</mo><mo>∞</mo></mrow></munder><mo>&nbsp;</mo><mn>5</mn></math>`,
  };

  constructor(
    private _router: Router,
    public http: HttpClient,
    public settingsService: SettingsService,
    private userService: UserService
  ) {

    this.getAllSettings();

  }
  socket = io('https://websocket.testat-app.com', {
    reconnection: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 1000,
  });
  ngOnInit() {
    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        Helpers.setLoading(true);
        Helpers.bodyClass(this.globalBodyClass);
      }
      if (route instanceof NavigationEnd) {
        Helpers.setLoading(false);
      }
    });

    if (localStorage.getItem('currentUser')) {
      let userInfo:any = JSON.parse(localStorage.getItem('currentUser'))
      this.userService.setuserdata(userInfo)
    }
  }

  getAllSettings() {

    this.settingsService.getAllSettings().subscribe((data) => {
      if (data) {
        //console.log(data);
        localStorage.setItem('settings', JSON.stringify(data));
      }

    });
  }
}
