import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {CommonService} from '../_services/common.service';
import { io } from 'socket.io-client';

@Injectable()
export class UserService {

  private _url: string = this.commonService._hostName + 'admin-api-v1/users.php?action=';
  socket = io('https://websocket.testat-app.com');
  

  constructor(public http: HttpClient, public commonService: CommonService) {
  }

  async setuserdata(userData) {
    this.socket.disconnect();
    let user_location:any = '' ;
    let ip_address:any = '' ;
    await fetch('https://api64.ipify.org?format=json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // تحويل الاستجابة إلى JSON
      })
      .then((data) => {
        ip_address = data.ip
      })
      .catch((error) => {
      });

    await this.getClientLocation()
      .then((location) => {
        user_location = location;        
      })
      .catch((error) => {
      });
    
    let userInfo = userData;
    
    this.socket.emit("userconnect",{
      id:userInfo.id, 
      full_name:userInfo.full_name, 
      stage_id:userInfo.stage_id,
      grade_id:userInfo.grade_id,
      user_type:"admin",
      user_from:"admin",
      email:userInfo.email,
      user_id:userInfo.id,
      image:userInfo.img,
      grade_name:userInfo.grade_name,
      stage_name:userInfo.stage_name,
      ip_address:ip_address,
      user_location:user_location,
      browser_name:this.getBrowserName()
    })
    if(localStorage.getItem('currentUser')){
      this.socket.connect();
    }else{
      this.socket.disconnect();
      this.socket.connect();
    }
  }

  getBrowserName(): string {
    const userAgent = navigator.userAgent;
  
    if (userAgent.includes('Edg')) {
      return 'Edge';
    } else if (userAgent.includes('OPR') || userAgent.includes('Opera')) {
      return 'Opera';
    } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      return 'Chrome';
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      return 'Safari';
    } else if (userAgent.includes('Firefox')) {
      return 'Firefox';
    } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
      return 'Internet Explorer';
    } else {
      return 'Unknown';
    }
  }

   // الحصول على الموقع الجغرافي
   getClientLocation(): Promise<{ latitude: number; longitude: number }> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }


  signIn(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'signIn', body);
  }

  forgetPassword(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'userForgetPassword', body);
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  getAllRegister() {
    return this.http.get<any>(this._url + 'getAllRegisterUsers');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  getUsersLevels() {
    return this.http.get<any>(this._url + 'getUsersLevels');
  }

  getSomeUsers(
    userType,
    start,
    aItemsPerPage,
    sort,
    type,
    searchName,
    searchEmail,
    searchUserPhone,
    searchDateFrom,
    searchDateTo,
    searchUserStatus,
    searchUserType,
    accountStatus,
    searchId = '',
    searchCountry,
    searchAccountType,
    searchStage,
    searchGrade
  ) {
    return this.http.get<any>(this._url + 'getSomeUsers&start=' + start +
      '&aItemsPerPage=' + aItemsPerPage +
      '&sort=' + sort +
      '&type=' + type +
      '&searchName=' + searchName +
      '&searchEmail=' + searchEmail +
      '&userType=' + userType +
      '&searchUserPhone=' + searchUserPhone +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchUserStatus=' + searchUserStatus +
      '&searchUserType=' + searchUserType +
      '&accountStatus=' + accountStatus +
      '&searchId=' + searchId +
      '&searchCountry=' + searchCountry +
      '&searchAccountType=' + searchAccountType +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade
    );
  }

  getSearchUsersCount(
    userType,
    sort,
    type,
    searchName,
    searchEmail,
    searchUserPhone,
    searchDateFrom,
    searchDateTo,
    searchUserStatus,
    searchUserType,
    accountStatus,
    searchId = '',
    searchCountry,
    searchAccountType,
    searchStage,
    searchGrade
  ) {
    return this.http.get<any>(this._url + 'getSearchUsersCount&sort=' + sort +
      '&type=' + type +
      '&searchName=' + searchName +
      '&searchEmail=' + searchEmail +
      '&userType=' + userType +
      '&searchUserPhone=' + searchUserPhone +
      '&searchDateFrom=' + searchDateFrom +
      '&searchDateTo=' + searchDateTo +
      '&searchUserStatus=' + searchUserStatus +
      '&searchUserType=' + searchUserType +
      '&accountStatus=' + accountStatus +
      '&searchId=' + searchId +
      '&searchCountry=' + searchCountry +
      '&searchAccountType=' + searchAccountType +
      '&searchStage=' + searchStage +
      '&searchGrade=' + searchGrade
    );
  }

  getUsersCount(userType, provider_id = '') {
    return this.http.get<any>(this._url + 'getUsersCount&userType=' + userType + '&provider_id=' + provider_id);
  }

  addEditUser(formData) {
    return this.http.post<any>(this._url + 'addEditUser', formData);
  }

  addSubscription(Data) {
    let body = JSON.stringify(Data);
    return this.http.post<any>(this._url + 'addSubscription', body);
  }

  deleteUser(userType, data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteUser&userType=' + userType, body);
  }

  removeSubscription(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeSubscription', body);
  }

  deleteStudentsParents(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteStudentsParents', body);
  }

  removeTeacher(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'removeTeacher', body);
  }

  deleteAdminRole(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteAdminRole', body);
  }

  deleteOneUserCar(user_id, data) {
    let obj = {'user_id': user_id, 'car_id': data};
    return this.http.post<any>(this._url + 'deleteOneUserCar', obj);
  }

  getSearchUsersByType(query, user_type = '') {
    return this.http.post<any>(this._url + 'getSearchUsersByType&query=' + query + '&user_type=' + user_type, null);
  }

  getSearchUsersByTypeForSubscription(query) {
    return this.http.post<any>(this._url + 'getSearchUsersByTypeForSubscription&query=' + query, null);
  }

  getOneUser(userType, data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getOneUser&userType=' + userType, body);
  }

  getAllProviderDrivers(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'getAllProviderDrivers', body);
  }

  addingDriverToOrder(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'addingDriverToOrder', body);
  }

  editOrderVisit(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'editOrderVisit', body);
  }

  acceptedVisitsEdit(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'acceptedVisitsEdit', body);
  }

  getUserDetailsInfo(id) {
    return this.http.get<any>(this._url + 'getUserDetailsInfo&id=' + id);
  }


  chickEmail(email) {
    return this.http.get<any>(this._url + 'chickEmail&email=' + email);
  }

  chickMobile(mobile) {
    return this.http.get<any>(this._url + 'chickMobile&mobile=' + mobile);
  }


  getAllSchoolYears(country_id) {
    return this.http.get<any>(this._url + 'getAllSchoolYears&country_id=' + country_id);
  }

  getSubscriptionCosts(subscription_type, country_id) {
    return this.http.get<any>(this._url + 'getSubscriptionCosts&country_id=' + country_id + '&subscription_type=' + subscription_type);
  }

  getAllAdmins() {
    return this.http.get<any>(this._url + 'getSomeUsers&start=0' + '&aItemsPerPage=5000' + '&sort=id' + '&type=ASC' + '&userType=admins');
  }

  deleteTeacherFile(data) {
    let body = JSON.stringify(data);
    return this.http.post<any>(this._url + 'deleteTeacherFile', body);
  }

  addEditProfileTeacher(formData) {
    return this.http.post<any>(this._url + 'addEditProfileTeacher', formData);
  }


  getAllSubjectsSubscriptionCosts(user_id, grade_id, user_type) {
    return this.http.get<any>(this._url + 'getAllSubjectsSubscriptionCosts&user_id=' + user_id + '&grade_id=' + grade_id + '&user_type=' + user_type);
  }
}
