import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import {Title} from '@angular/platform-browser';

declare var jquery: any;
declare var $: any;


export class UploadAdapter {
  private loader;
  constructor(loader) {
    this.loader = loader;

  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        var myReader = new FileReader();
        myReader.onloadend = (e) => {
          resolve({default: myReader.result});
        };

        myReader.readAsDataURL(file);
      }));
  };
}

@Injectable()
export class CommonService {

  // ---------- local -----------

  // public _hostName = 'http://localhost/testat/api/';
  // public _hostNamePrint = 'http://localhost/testat/';

  // ---------- live -----------

  // public _hostNamePrint = 'https://testat-app.com/print/';
  // public _hostName = 'https://testat-app.com/api/';


  // ----------tesssstttt   live -----------

  public _hostNamePrint = 'https://api.testat-app.com/print/';
  public _hostName = 'https://api.testat-app.com/';


  //  ---------- develop -----------

//    public _hostNamePrint ='https://develop.testat-app.com/print/';
//    public _hostName ='https://develop.testat-app.com/api/';


  public permissionMsgType = 'تنبيه';
  public permissionMsg = 'ليس لديك الصلاحية للقيام بهذه المهمة';
  public requestNotFoundMsg = 'هذا الطلب غير موجود.';
  public canEditMsg = 'هذا المحتوى غير موجود ! يرجى التحقق من البيانات المدخلة.';

  public aItemsPerPage = 100;
  public aLinksPerPage = 60;
  public selectedTab = 0;

  public user_id: string = '';


  items: any[];
  public test_questions_to_bank: any[] = [];

  constructor(private titleService: Title,
    private router: Router, private http: HttpClient) {

    this.items = (this.items instanceof Array) ? this.items : [];

    if (localStorage.getItem('currentUser') !== null) {
      var currentUser = localStorage.getItem('currentUser');
      var retrievedObject = JSON.parse(currentUser);
      this.user_id = retrievedObject.id;
    }

  }


///////////////////


  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


////////////

  public setTitle(newTitle: string) {
    this.titleService.setTitle('Testat | ' + newTitle);
  }

  getUserLevel(data) {
    let body = JSON.stringify(data);
    let response = this.http.post<any>(this._hostName + 'admin-api-v1/users.php?action=getUserLevel', body);

    response.subscribe(data => {
      console.log(data.is_disabled && !data.success);
      if (data.is_disabled && !data.success) {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("token");
        this.router.navigate(['/login']);

      }
    });

    return response;
  }

  getAllLanguages() {
    return this.http.get<any>(this._hostName + 'admin-api-v1/languages.php?action=getAllLanguages');
  }

  getAllCities(countryId) {
    return this.http.get<any>(this._hostName + 'admin-api-v1/places.php?action=getAllCities&countryId=' + countryId);
  }

  getHomeStatistics() {
    let response = this.http.get<any>(this._hostName + 'admin-api-v1/statistics.php?action=getHomeStatistics');

    response.subscribe(data => {
      console.log(data.is_disabled && !data.success);
      if (data.is_disabled && !data.success) {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("token");
        this.router.navigate(['/login']);

      }
    });

    return response;
  }

  getProviderHomeStatistics(id) {
    return this.http.get<any>(this._hostName + 'admin-api-v1/statistics.php?action=getProviderHomeStatistics&provider_id=' + id);
  }

  getHomeDriversLocations() {
    return this.http.get<any>(this._hostName + 'admin-api-v1/statistics.php?action=getHomeDriversLocations');
  }

  goToTab(inx) {
    this.selectedTab = inx;
  }

  slug(txt: string) {
    // let str = '#this is_#a test*from$test@test%test(test)test=test&test^test!test+test:test<>/\\{}[]?';
    let new_txt = txt.replace(/[#|_| |*|$|@|%|(|)|=|&|^|!|+|:|<|>|/|'\\'|}|{|'['|'\]'|?]/g, '-');
    return new_txt;
  }

  limitString(txt: string, txtLength: number) {
    if (txt.length > txtLength) {
      const str = txt.substring(0, txtLength);
      return str + ' ...';
    } else {
      return txt;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  check_all(event) {

    if (event.target.checked) {
      var ids = [];
      $('input[class="checkbox_item"]:checkbox').each(function() {
        ids.push($(this).val());
        $(this).prop('checked', true);
      });

      this.items = ids;
    } else {
      $('input[class="checkbox_item"]:checkbox').each(function() {
        $(this).prop('checked', false);
      });
      this.items.length = 0;
    }
  }

  check_all_status(event) {

    if (event.target.checked) {
      var ids = [];
      $('input[class="checkbox_item_status"]:checkbox').each(function() {
        ids.push({id: $(this).val(), status: $(this).attr('data-status')});
        $(this).prop('checked', true);
      });

      this.items = ids;
    } else {
      $('input[class="checkbox_item_status"]:checkbox').each(function() {
        $(this).prop('checked', false);
      });
      this.items.length = 0;
    }
  }

  check_all_comment(event) {

    if (event.target.checked) {
      var ids = [];
      $('input[class="checkbox_item_comment"]:checkbox').each(function() {
        ids.push({id: $(this).val(), status: $(this).attr('data-status')});
        $(this).prop('checked', true);
      });

      this.items = ids;
    } else {
      $('input[class="checkbox_item_comment"]:checkbox').each(function() {
        $(this).prop('checked', false);
      });
      this.items.length = 0;
    }
  }

  check_all_tests(event) {
    if (event.target.checked) {
      var ids = [];
      $('input[class="checkbox_item_bank"]:checkbox').each(function() {
        ids.push($(this).val());
        $(this).prop('checked', true);
      });

      this.test_questions_to_bank = ids;
    } else {
      $('input[class="checkbox_item_bank"]:checkbox').each(function() {
        $(this).prop('checked', false);
      });
      this.test_questions_to_bank.length = 0;
    }
  }

  toggleCheckbox(event, id) {


    if (event.target.checked) {
      this.items.push(id);
    } else {
      $('input[class="check_all"]:checkbox:checked').prop('checked', false);
      var index = this.items.indexOf(id);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    }
  }

  toggleCheckboxStatus(event, id) {

    if (event.target.checked) {
      let _status = event.target.getAttribute('data-status');
      this.items.push({id: id, status: _status});
    } else {
      $('input[class="check_all_status"]:checkbox:checked').prop('checked', false);
      const index = this.items.findIndex(object => {
        return object.id === id;
      });

      if (index > -1) {
        this.items.splice(index, 1);
      }
    }
  }
  ids = [];
  result:any;
  toggleCheckboxForTests(event, id, grade_id) {
    let dt;
    dt = {"id":id, "grade":grade_id}
    if (event.target.checked) {
      this.items.push(dt);
    } else {
      $('input[class="check_all"]:checkbox:checked').prop('checked', false);
      var index = this.items.indexOf(dt);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    }

    ///////////////////////////////////////////

    const result: Record<string, string[]> = {};
    this.items.forEach((item) => {
        const { id, grade } = item;

        if (!result[grade]) {
            result[grade] = [];
        }

        result[grade].push(id); 
    });
    console.log('mohamed' , result);
    this.result = result;
    
  }
  toggleCheckboxComment(event, id) {

    if (event.target.checked) {
      let _status = event.target.getAttribute('data-status');
      this.items.push({id: id, status: _status});
    } else {
      $('input[class="check_all_comment"]:checkbox:checked').prop('checked', false);
      const index = this.items.findIndex(object => {
        return object.id === id;
      });

      if (index > -1) {
        this.items.splice(index, 1);
      }
    }
  }

  toggleCheckboxTestBank(event, id) {

    if (event.target.checked) {
      this.test_questions_to_bank.push(id);
    } else {
      $('input[class="check_all_test_bank"]:checkbox:checked').prop('checked', false);
      var index = this.test_questions_to_bank.indexOf(id);
      if (index > -1) {
        this.test_questions_to_bank.splice(index, 1);
      }
    }
  }

  toggleCheckboxTest(event, id) {

    if (event.target.checked) {
      this.test_questions_to_bank.push(id);
    } else {
      $('input[class="check_all"]:checkbox:checked').prop('checked', false);
      var index = this.test_questions_to_bank.indexOf(id);
      if (index > -1) {
        this.test_questions_to_bank.splice(index, 1);
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////

  range(start, end, step) {
    var range = [];
    var typeofStart = typeof start;
    var typeofEnd = typeof end;

    if (step === 0) {
      throw TypeError('Step cannot be zero.');
    }

    if (typeofStart == 'undefined' || typeofEnd == 'undefined') {
      throw TypeError('Must pass start and end arguments.');
    } else if (typeofStart != typeofEnd) {
      throw TypeError('Start and end arguments must be of same type.');
    }

    typeof step == 'undefined' && (step = 1);

    if (end < start) {
      step = -step;
    }

    if (typeofStart == 'number') {

      while (step > 0 ? end >= start : end <= start) {
        range.push(start);
        start += step;
      }

    } else if (typeofStart == 'string') {

      if (start.length != 1 || end.length != 1) {
        throw TypeError('Only strings with one character are supported.');
      }

      start = start.charCodeAt(0);
      end = end.charCodeAt(0);

      while (step > 0 ? end >= start : end <= start) {
        range.push(String.fromCharCode(start));
        start += step;
      }

    } else {
      throw TypeError('Only string and number types are supported');
    }

    return range;

  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  matchYoutubeUrl(url) {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
      // return url.match(p)[1];
      return 'https://www.youtube.com/embed/' + url.match(p)[1];
    }
    return false;
  }

  isValidURL(string) {
    var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null)
  }
}
